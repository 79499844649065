import { Event } from '@app-core/eventTracking/Event'
import { EventTracker } from '@app-core/eventTracking/EventTracker'
import { LoginStatus, UserProperty } from '@app-core/eventTracking/properties'
import mixpanel from 'mixpanel-browser'
import ObjectUtil from '@app-core/libs/ObjectUtil'

interface ProfileProperties extends Partial<UserProperty> {
  $name?: string
  $onesignal_user_id?: string
}

export enum Platform {
  web = 'web',
  mweb = 'mweb',
}

export default class MixpanelTracker implements EventTracker {
  private user?: UserProperty
  private oneSignalUserId?: string
  private platform: Platform

  constructor(mixpanelToken: string, platform?: Platform) {
    this.platform = platform
    mixpanel.init(mixpanelToken, {
      loaded: () => {},
    })
  }

  private setMixpanelUserProfile() {
    const profile: ProfileProperties = {}

    const hasLoggedInUser = !!(this.user && this.user.loginStatus === LoginStatus.LOGGED_IN)
    const hasOneSignalUserId = !!this.oneSignalUserId
    const noProfileParams = !hasLoggedInUser && !hasOneSignalUserId

    if (noProfileParams) return

    // add user properties
    if (hasLoggedInUser) {
      Object.assign(profile, { ...this.user, $name: this.requireUserId() })
    }

    // add OneSignal userId
    if (hasOneSignalUserId) {
      profile.$onesignal_user_id = this.oneSignalUserId
    }
    // set user profile
    mixpanel.people.set(profile)
  }

  private requireUserId(): string {
    if (!this.user || !this.user.userId) {
      throw new Error('userId not found while calling requireUserId')
    }
    return this.user.userId
  }

  setTrackUser(user: UserProperty): void {
    this.user = user

    // set user identity if logged in
    const isLoggedIn = user.loginStatus === LoginStatus.LOGGED_IN
    if (isLoggedIn) {
      mixpanel.identify(user.userId)
    }
    this.setMixpanelUserProfile()

    // set super properties
    const superProperties = ObjectUtil.flatten({ user })
    mixpanel.register(superProperties)
  }

  track<T>(event: Event<T>): void {
    const { platform } = this
    const flattenedProperties = ObjectUtil.flatten(event.properties)
    if (platform) Object.assign(flattenedProperties, { platform })

    mixpanel.track(event.name, flattenedProperties, { transport: 'sendBeacon' })
  }

  setOneSignalUserId(oneSignalUserId: string): void {
    this.oneSignalUserId = oneSignalUserId
    this.setMixpanelUserProfile()
  }
}
