import flatFlatten from 'flat'

type AnyObject = Record<string, any> | Array<any>

export interface ObjectEntry<T = unknown> {
  key: string
  value: T
}
export type ObjectRejecter<T = unknown> = (obj: ObjectEntry<T>) => boolean

// ref: https://stackoverflow.com/questions/52367849/remove-empty-null-values-from-nested-object-es6-clean-nested-objects
export function clean(inputObject: AnyObject, shouldReject?: ObjectRejecter): AnyObject {
  Object.entries(inputObject).forEach(([key, value]) => {
    if (value && typeof value === 'object') {
      clean(value as AnyObject, shouldReject)
    }
    const isNonEmptyObject = value && typeof value === 'object' && !Object.keys(value).length
    if (isNonEmptyObject || value === null || value === undefined) {
      if (Array.isArray(inputObject)) {
        const index = parseInt(key)
        const array = inputObject as Array<unknown>
        array.splice(index, 1)
      } else {
        delete inputObject[key]
      }
    } else if (shouldReject) {
      const wrappedObject = inputObject as Record<string, unknown>
      shouldReject({ key, value }) && delete wrappedObject[key]
    }
  })
  return inputObject
}

interface FlattenOptions {
  shouldFlattenArray?: boolean
  delimiter?: string
}
const emptyFlattenOptions = {}
export function flatten(
  inputObject: AnyObject,
  options: FlattenOptions = emptyFlattenOptions,
): AnyObject {
  const { shouldFlattenArray, delimiter } = options
  return flatFlatten(inputObject, { safe: !shouldFlattenArray, delimiter }) // When enabled safe, arrays and their contents will be preserved.
}

export default {
  clean,
  flatten,
}
