import { EventTracker } from '@app-core/eventTracking/EventTracker'
import { Event } from '@app-core/eventTracking/Event'
import { UserProperty } from '@app-core/eventTracking/properties/UserProperty'
import Config from '@public-config'
import { Nullable } from '@app-core/types'
import MixpanelTracker, { Platform } from './MixpanelTracker'
import ObjectUtil from '@app-core/libs/ObjectUtil'
import RNApp from '@libs/RNApp'

class NullEventTracker implements EventTracker {
  eventQueue = []
  user = null

  setTrackUser(user: UserProperty) {
    this.user = user
  }

  track<T>(event: Event<T>) {
    this.eventQueue.push(event)
  }
}

class ConsoleEventTracker implements EventTracker {
  setTrackUser(user: UserProperty) {
    console.log('setting user property to', JSON.stringify(user, null, 2))
  }

  track<T>(event: Event<T>) {
    console.log('log event:', JSON.stringify(event, null, 2))
  }
}

class CompositeEventTracker implements EventTracker {
  private readonly trackers: Array<EventTracker> = []

  private handleProperties(properties: any): void {
    if (properties) {
      const fromPageProperties = properties.fromPage
      if (fromPageProperties) {
        const isEmptyFromPageName = !fromPageProperties.pageName
        const isEmptyFromPageUrl = !fromPageProperties.pageUrl
        if (isEmptyFromPageName && isEmptyFromPageUrl) {
          properties.fromPage = undefined
        }
      }
      // remove empty properties
      ObjectUtil.clean(properties, ({ value }) => !value)
    }
  }
  setTrackUser(user: UserProperty): void {
    this.trackers.forEach((tracker) => tracker.setTrackUser(user))
  }

  track<T>(event: Event<T>): void {
    this.handleProperties(event.properties)
    this.trackers.forEach((tracker) => {
      tracker.track(event)
    })
  }

  addTracker(tracker: EventTracker) {
    this.trackers.push(tracker)
  }
}

class RNAppEventTracker implements EventTracker {
  constructor() {}
  setTrackUser(user: UserProperty) {}

  track<T>(event: Event<T>) {
    RNApp.trackEvent(event)
  }
}

const nullTracker = new NullEventTracker()

let webEventTracker: Nullable<CompositeEventTracker> = null
let rnAppEventTracker: Nullable<EventTracker> = null
let mixpanelTracker: Nullable<MixpanelTracker> = null

export function getEventTracker(): EventTracker {
  if (rnAppEventTracker) return rnAppEventTracker
  if (webEventTracker) return webEventTracker
  return nullTracker
}

export function resetEventTracker(): void {
  webEventTracker = null
  rnAppEventTracker = null
  mixpanelTracker = null
}

export async function initEventTracker(isApp?: boolean, platform?: Platform): Promise<void> {
  // 如果 init 過了就不要再執行了
  if (webEventTracker) return

  webEventTracker = new CompositeEventTracker()

  if (Config.env === 'development') {
    // add console tracker if needed
    webEventTracker.addTracker(new ConsoleEventTracker())
  }

  if (isApp) {
    webEventTracker.addTracker(new RNAppEventTracker())
  } else if (Config.mixpanelEnabled === 'enabled') {
    mixpanelTracker = new MixpanelTracker(Config.mixpanelToken, platform)
    // add mixpanel tracker
    webEventTracker.addTracker(mixpanelTracker)
  }

  nullTracker.eventQueue.forEach((event) => webEventTracker.track(event))
  if (nullTracker.user) webEventTracker.setTrackUser(nullTracker.user)
}

export function setOneSignalUserIdToTrackers(oneSignalUserId: string) {
  if (mixpanelTracker) {
    mixpanelTracker.setOneSignalUserId(oneSignalUserId)
  }
}
