/**
 * Reference: https://github.com/popdaily/popdaily.tracking/blob/develop/amplitude/events/user_property.md
 */

import { SignUpPlatform } from '../../currentUser/User'

export interface UserProperty {
  loginStatus: LoginStatus
  userId: string
  signUpAt?: string
  signUpType?: SignUpType
  signUpPlatform?: SignUpPlatform

  // not implemented
  userRoles?: Array<string>
  appFirstInstallDate?: string
  appLastUpdateDate?: string
  age?: number
}

export enum LoginStatus {
  GUEST = 'guest',
  LOGGED_IN = 'login',
}

export enum SignUpType {
  FACEBOOK = 'facebook',
  APPLE = 'apple',
  EMAIL = 'email',
  PHONE = 'phone',
}
