import { PageProperty, CampaignProperty } from '@app-core/eventTracking/properties'
import omitBy from 'lodash/omitBy'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'

export function getCurrentPagePropertyFor(
  pageName: string,
  pageUrl?: string,
  pageComponent?: string,
): PageProperty {
  return {
    pageName,
    pageUrl: pageUrl || (typeof window !== 'undefined' && window.location.href),
    // TODO: 在 browser 環境以某種方式來定義 `region` 而非 hard coded
    region: 'Taiwan',
    pageComponent,
  }
}

export type NextPagePropertyForInputType = {
  pageName?: string
  pageUrl: string
}

export function getNextPagePropertyFor({
  pageName,
  pageUrl,
}: NextPagePropertyForInputType): PageProperty {
  return {
    pageName,
    pageUrl,
    // TODO: 在 browser 環境以某種方式來定義 `region` 而非 hard coded
    region: 'Taiwan',
  }
}

export type FromPagePropertyForInputType = {
  pageName?: string
  pageUrl?: string
  pageComponent?: string
}

export function getFromPagePropertyFor({
  pageName,
  pageUrl,
  pageComponent,
}: FromPagePropertyForInputType): PageProperty {
  return {
    pageName,
    pageUrl,
    // TODO: 在 browser 環境以某種方式來定義 `region` 而非 hard coded
    region: 'Taiwan',
    pageComponent,
  }
}

export function getCampaignProperty(): CampaignProperty {
  const params = new URLSearchParams(window.location.search)
  const properties = omitBy(
    {
      utm_source: params.get('utm_source'),
      utm_medium: params.get('utm_medium'),
      utm_campaign: params.get('utm_campaign'),
      utm_term: params.get('utm_term'),
      utm_content: params.get('utm_content'),
    },
    isNil,
  )
  return isEmpty(properties) ? null : properties
}
